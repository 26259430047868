import React from "react";

import { NextPage } from "next";
import dynamic from "next/dynamic";

import { sizeMaps } from "lib/dfp";

import PostGridSkeleton from "@/components/skeletons/PostGrid";
import Page from "@/layouts/BasePage";

import type { SiteProps } from "utils/types";
import type { AdSlotsMap } from "utils/types/ads";
import type { ImagePost, Pagination } from "utils/types/content";

const ImagePostsComponent = dynamic(
  () => import("@/components/Content/PostsGridWrapper"),
  { ssr: false, loading: () => <PostGridSkeleton withTitle /> }
);

interface Props extends SiteProps {
  posts: ImagePost[];
  meta: Pagination;
}

const getTeamAds = (teamName: string): AdSlotsMap => ({
  dleaderboard: {
    adUnit: `/TPL_${teamName}_Article_Desk_Leaderboard_728Flex`,
    size: sizeMaps.leaderboard,
    divId: "div-gpt-ad-1696020574480-0",
  },
  dflex1: {
    adUnit: `/TPL_${teamName}_Article_Desk_MPU_300_1`,
    size: sizeMaps.flexBoxSmall,
    divId: "div-gpt-ad-1696020786673-0",
  },
  dflex2: {
    adUnit: `/TPL_${teamName}_Article_Desk_MPU_300_2`,
    size: sizeMaps.flexBoxSmall,
    divId: "div-gpt-ad-1696021088950-0",
  },
  dflex3: {
    adUnit: `/TPL_${teamName}_Article_Desk_MPU_300_3`,
    size: sizeMaps.flexBoxLarge,
    divId: "div-gpt-ad-1696021201921-0",
  },
  mflex1: {
    adUnit: `/TPL_${teamName}_Article_Mobile_Leaderboard_320`,
    size: sizeMaps.mobile,
    divId: "div-gpt-ad-1696021405151-0",
  },
  mflex2: {
    adUnit: `/TPL_${teamName}_Article_Mobile_Leaderboard_320_2`,
    size: sizeMaps.mobile,
    divId: "div-gpt-ad-1696021657017-0",
  },
  mflex3: {
    adUnit: `/TPL_${teamName}_Article_Mobile_Leaderboard_320_3`,
    size: sizeMaps.mobile,
    divId: "div-gpt-ad-1696021846362-0",
  },
  mflex4: {
    adUnit: `/TPL_${teamName}_Article_Mobile_Leaderboard_320_4`,
    size: sizeMaps.mobile,
    divId: "div-gpt-ad-1696021938987-0",
  },
  mflex5: {
    adUnit: `/TPL_${teamName}_Article_Mobile_Leaderboard_320_5`,
    size: sizeMaps.mobile,
    divId: "div-gpt-ad-1696021993032-0",
  },
});

const Content: NextPage<Props> = ({ posts, meta, team, ...props }) => {
  return (
    <Page
      team={team}
      metadata={{ title: `Articles - ${team.shortName}` }}
      {...props}
    >
      <ImagePostsComponent
        initialPosts={posts}
        meta={meta}
        title="Articles"
        team={team.shortName}
        adSlots={getTeamAds(team.shortName)}
      />
    </Page>
  );
};

export default Content;

export async function getStaticProps({ params }) {
  const { team } = params;

  const { fetchSiteProps } = await import("@/utils/fetchers");
  const siteProps = await fetchSiteProps(team);

  if (siteProps.team) {
    const { getContentService } = await import("api/content-service");
    const { posts, meta } = await getContentService()
      .posts(siteProps.team.shortName)
      .getPaginatedPosts("image_posts", { limit: 10 });

    return {
      props: { posts, meta, ...siteProps },
      revalidate: 60,
    };
  }

  return { notFound: true };
}

export async function getStaticPaths() {
  const { getSiteService } = await import("api/site-service");
  const allTeams = await getSiteService().teams().fetchAll();

  return {
    paths:
      allTeams.map((team) => ({
        params: { team: team.shortName.toLowerCase() },
      })) ?? [],
    fallback: "blocking",
  };
}
